<template>
  <div>
    <div class="title">领导成员</div>
    <leaderCard />
  </div>
</template>

<script>
import leaderCard from "~scb/components/survey/leader/leaderCard";
export default {
  metaInfo: {
    title: "领导成员",
  },
  components: { leaderCard },
};
</script>

<style lang="less" scoped>
.title {
  font-size: 24px;
  font-weight: bold;
  color: #666666;
  margin-top: 13px;
  margin-bottom: 24px;
}
</style>